import Vue from 'vue';
import core, { CoreOptions } from '@glittr/frontend-core';
import router from './router';
import services from './services';
import pLanguageSelection from './components/p-language-selection.vue';
import spvRaiseNowTamaro from './components/spv-raise-now-tamaro.vue';

Vue.config.productionTip = false;
// Core entry point
Vue.use(core, {
  // appId: 'custom-mount-id'
  router,
  Vue,
} as CoreOptions);

// APP____________________________________________________________

// STYLES
require('./assets/styles/main.scss');

// PLUGINS
Vue.use(services);

// COMPONENTS
Vue.component('PLanguageSelection', pLanguageSelection);
Vue.component('SpvRaiseNowTamaro', spvRaiseNowTamaro);

// GUARDS
// Vue.$guards.push(yourGuard);

// LAYOUTS
const reqLayouts = require.context('./views/layouts', true);
reqLayouts.keys().forEach((componentPath) => {
  let componentName = componentPath.substr(componentPath.lastIndexOf('/') + 1, componentPath.length - 1);
  componentName = componentName.replace('.vue', '');
  const component = reqLayouts(componentPath).default;
  Vue.component(componentName, component);
});

// _______________________________________________________________

require('./store');

declare module '@core/plugins/config/config' {
  interface ConfigType {
    /** Shows the debug panel */
    'raisenow-tamaro-debug': boolean,
    /** Testmode enables test payments, if set to false real payments are performed! */
    'raisenow-tamaro-testmode': boolean,
    /** Fixed amount to pay with, is either a number, or false to disable fixed amount */
    'raisenow-tamaro-fixed-amount': boolean | number,
  }
}
