




















import Vue from 'vue';

export default Vue.extend({
  name: 'TestRaisenow',
  layout: 'layout-widget',
  props: {

  },
  data: () => ({

  }),
});
